<template>
    <div class="ConfirmedDetail">
        <main-header menuActive="confirmed" />

        <div id="contentsWrap">
            <div class="container sub">
                <div class="contentHead">
                    <h2>확진자관리</h2>
                </div>

                <div class="row">
                    <div class="subHead">
                        <h3># 확진자정보
                            <span style="color: darkred; font-size: 12px;">확진자 정보는 필수항목으로 모두 입력해 주세요.</span>
                        </h3>
                    </div>
                    <table class="table table-profile">
                        <colgroup>
                            <col width="10%">
                            <col width="25%">
                            <col width="10%">
                            <col width="25%">
                            <col width="10%">
                            <col width="20%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">지역</th>
                            <td class="text-left">
                                <div class="SearchCombo inline-block">
                                    <select class="select_ansimi width150"
                                            v-model="f_region"
                                            ref="f_region"
                                    >
                                        <option v-for="(data, index) in list_region" :key="index" :value="data.code">
                                            {{data.name}}
                                        </option>
                                    </select>
                                </div>
                            </td>
                            <th class="field">확진일</th>
                            <td class="text-left">
                                <!--                              <input type="text" class='input type2 width100' v-model="f_confirm_date" maxlength="10" />-->
                                <div class="dataGroup">
                                    <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :typeable="true" v-model="f_confirm_date"></datepicker>
                                    <span class="icon_calendar"></span>
                                </div>
                            </td>
                            <th class="field">접수일</th>
                            <td class="text-left">
                                <div class="dataGroup">
                                    <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :typeable="true" v-model="f_reg_date"></datepicker>
                                    <span class="icon_calendar"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">이름</th>
                            <td class="text-left">
                                <input type="text" ref="f_patient_name" class='input type2 width-100' v-model="f_patient_name" />
                               <input type="hidden" ref="userDataAdminName" class='input type2 width-100' v-model="userDataAdminName"/>
                            </td>
                            <th class="field">성별</th>
                            <td class="text-left">
                                <label v-for="(data, index) in list_sex" :key="index"
                                       class="mrg-right-10"
                                >
                                    <input type="radio"
                                           :value="data.code"
                                           v-model="f_patient_sex"
                                    />
                                    {{data.name}}
                                </label>
                            </td>
                            <th class="field">생년월일</th>
                            <td class="text-left">
                                <!--                              <input type="text" class='input type2 width100' v-model="f_patient_birth" maxlength="10" />-->
                                <div class="dataGroup">
                                    <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :typeable="true" v-model="f_patient_birth" ></datepicker>
                                    <span class="icon_calendar"></span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">배정대상</th>
                            <td class="text-left">
                                <label v-for="(data, index) in list_assign_type" :key="index"
                                       class="mrg-right-10"
                                >
                                    <input type="radio"
                                           :value="data.code"
                                           v-model="f_assign_type"
                                    />
                                    {{data.name}}
                                </label>

                            </td>
                            <th class="field">배정여부</th>
                            <td class="text-left">
                                <label v-for="(data, index) in list_assign_state_view" :key="index"
                                       class="mrg-right-10"
                                >
                                    <input type="radio"
                                           :value="data.code"
                                           v-model="f_assign_state_view"
                                           @change="($event.target.value == 'G0302' || $event.target.value == 'G0312' || $event.target.value == 'G0313' ) ? f_assign_state = $event.target.value : '' "
                                    />
                                    {{data.name}}
                                </label>
                            </td>
                            <th class="field">입소일</th>
                            <td class="text-left">
                              <div class="dataGroup">
                                <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :typeable="true" v-model="f_transfer_date" ></datepicker>
                                <span class="icon_calendar"></span>
                              </div>
                            </td>
                        </tr>
                        <tr>
                          <th class="field">접수연번</th>
                          <td class="text-left">
                              <input type="text" class='input type2 width-100' v-model="f_date_num" />
                          </td>
                          <th class="field">국적</th>
                          <td class="text-left">
                            <label v-for="(data, index) in list_country" :key="index"
                                   class="mrg-right-10"
                            >
                              <input type="radio"
                                     :value="data.code"
                                     v-model="f_extra_4"
                              />
                              {{data.name}}
                            </label>

                          </td>
                          <td class="text-left" colspan="2" v-if="f_extra_4 == 'GR'">
                            <lavel >
                              <input type="text" class='input type2 width-100' v-model="f_extra_5" />
                            </lavel>
                          </td>

                        </tr>
                        <tr>
                          <th class="field">배정순서</th>
                          <td class="text-left">
                            <label v-for="(data, index) in list_assign_order" :key="index"
                                   class="mrg-right-10"
                            >
                              <input type="radio"
                                     :value="data.code"
                                     v-model="f_assign_order"
                              />
                              {{data.name}}
                            </label>
                          </td>
                          <th class="field">현재위치</th>
                          <td class="text-left" >
                            <label v-for="(data, index) in list_now_state" :key="index"
                                   class="mrg-right-10"
                            >
                              <input type="radio"
                                     :value="data.code"
                                     v-model="f_now_state"
                              />
                              {{data.name}}
                            </label>
                          </td>
                          <th class="field">비고</th>
                          <td class="text-left" >
                            <label v-for="(data, index) in list_etc" :key="index"
                                   class="mrg-right-10"
                            >
                              <input type="radio"
                                     :value="data.code"
                                     v-model="f_manage_check"
                              />
                              {{data.name}}
                            </label>
                          </td>
                        </tr>
                        <tr>
                            <th class="field">메모</th>
                            <td class="text-left" colspan="5">
                                <!--<input type="text" class='input type2 width-100' v-model="f_extra_3" />-->
                                <textarea type="text" class='input type2 width-100 height-100' v-model="f_extra_3"></textarea>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <div class="subHead">
                        <h3># 배정정보</h3>
                    </div>
                    <table class="table table-profile" v-if="f_assign_state_view == 'G0302' || f_assign_state_view == 'G0312' || f_assign_state_view == 'G0313'">
                        <colgroup>
                            <col width="10%">
                            <col width="25%">
                            <col width="10%">
                            <col width="25%">
                            <col width="10%">
                            <col width="20%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">배정일</th>
                            <td class="text-left">
                                <!--                              <input type="text" class='input type2 width100' v-model="f_assign_date" maxlength="10" />-->
                                <div class="dataGroup">
                                    <datepicker :input-class='["input", "type2", "width150"]' format="yyyy-MM-dd" :language="ko" :typeable="true" v-model="f_assign_date"></datepicker>
                                    <span class="icon_calendar"></span>
                                </div>
                            </td>
                            <th class="field">시설명</th>
                            <td class="text-left">
                                <select class="select_ansimi width150"
                                        v-model="f_fasility"
                                        :disabled="list_fasility.length == 1"
                                >
                                    <option v-for="(data, index) in list_fasility" :key="index" :value="data.code">
                                        {{data.name}}
                                    </option>
                                </select>
                            </td>
                            <th class="field">배정담당자</th>
                            <td class="text-left">
                                <select class="select_ansimi width150"
                                        v-model="f_manager"
                                >
                                    <option v-for="(data, index) in list_manager" :key="index" :value="data.code">
                                        {{data.name}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">배정상태</th>
                            <td class="text-left" colspan="3">

                                <label v-for="(data, index) in list_assign_state" :key="index"
                                       class="mrg-right-10"
                                >
                                    <input type="radio"
                                           :value="data.code"
                                           v-model="f_assign_state"
                                    />
                                    {{data.name}}
                                </label>

                            </td>
                            <th class="field">최종결과</th>
                            <td class="text-left">
                                <select class="select_ansimi width150"
                                        v-model="f_result"
                                >
                                    <option v-for="(data, index) in list_result" :key="index" :value="data.code">
                                        {{data.name}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr v-if="f_fasility == '181'">
                          <th class="field">재택치료 사유</th>
                          <td class="text-left">

                            <select class="select_ansimi width150"
                                    v-model="f_safecare"
                            >
                              <option v-for="(data, index) in list_safecare" :key="index" :value="data.code">
                                {{data.name}}
                              </option>
                            </select>

                          </td>
                          <th class="field" v-if="f_safecare == 'G1405'">기타사유</th>
                          <td class="text-left" v-if="f_safecare == 'G1405'"  colspan="3">
                            <input type="text" class='input type2 width-100' v-model="f_safecare_etc_reason" />
                          </td>
                        </tr>
                        </tbody>
                    </table>

                    <table class="table table-profile" v-else-if="f_assign_state_view == 'G0314'">
                      <colgroup>
                        <col width="10%">
                        <col width="90%">
                      </colgroup>
                      <tbody>
                      <tr>
                        <th class="field">기타사유</th>
                        <td class="text-left">
                          <input type="text" class='input type2 width-100' v-model="f_unassign_reason"  />
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    <table class="table table-profile" v-else>
                        <colgroup>
                            <col width="10%">
                            <col width="90%">
                        </colgroup>
                        <tbody>
                        <tr>
                            <th class="field">미배정사유</th>
                            <td class="text-left">
                                <select class="select_ansimi width150"
                                        v-model="f_unassign"
                                >
                                    <option value="">선택</option>
                                    <option v-for="(data, index) in list_unassign" :key="index" :value="data.code">
                                        {{data.name}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <th class="field">기타사유</th>
                            <td class="text-left">
                                <input type="text" class='input type2 width-100' v-model="f_unassign_reason"  />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row text-center no-pdd-top">
                    <div class="pull-left">
                        <button type="button" class="btn btn-lg btn-default" @click="listBackFunc()">목록</button>
                    </div>
                    <div class="pull-right">
                        <button type="button" class="btn btn-lg btn-primary" @click="sendFunc">저장</button>
                    </div>
                </div>
            </div>
        </div>
        <main-footer />
    </div>
</template>

<script>
import mainHeader from '@/components/MainHeader.vue';
import mainFooter from '@/components/MainFooter.vue';
import datepicker from 'vuejs-datepicker';
import {ko} from 'vuejs-datepicker/dist/locale'

let minDate = new Date(new Date().getFullYear(), new Date().getMonth(), 0);

let maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 1);

export default {
    name: 'ConfirmedDetail',
    data() {
        return {
            disabledDates: {
                to: minDate,
                from: maxDate
            },
            ko : ko,
            f_region : '',                      // 지역별 정보
            list_region : [],                   // 지역별 리스트
            f_confirm_date:this.$moment(new Date()).format('YYYY-MM-DD'),                  // 확진일
            f_reg_date:this.$moment(new Date()).format('YYYY-MM-DD'),                  // 확진일
            f_patient_name:'',                  // 이름
            f_date_num:'',                    // 접수연번
            f_patient_sex : 'M',                 // 성별
            list_sex : [],                      // 성별 리스트
            list_country : [],                  // 국가 리스트
            f_patient_birth:'',                 // 생년월일
            f_assign_type:'G0202',              // 배정대상
            list_assign_type : [],              // 배정대상 리스트
            f_assign_state_view: 'G0301',       // 배정여부
            f_transfer_date:this.$moment(new Date()).format('YYYY-MM-DD'),                   // 입소일
            list_assign_state_view : [          // 배정여부 리스트
                { code:'G0301', name:'미배정' },
                { code:'G0312', name:'배정진행중' },
                { code:'G0313', name:'재배정' },
                { code:'G0302', name:'배정완료' },
                { code:'G0314', name:'기타' }
            ],
            f_assign_date:this.$moment(new Date()).format('YYYY-MM-DD'),                   // 배정일
            f_manage_check : 'N',                // 비고정보
            list_etc : [],                      // 비고 리스트
            f_fasility : '',                    // 시설별 정보
            list_fasility : [],                 // 시설별 리스트
            list_fasility_org : [],             // 시설별 리스트
            f_fasility_upper_code : '',         // 시설 상위코드
            f_assign_state : '',                 // 배정상태
            list_assign_state : [],             // 배정상태 리스트
            f_manager : 'G0101',                // 배정담당자
            list_manager : [],                  // 배정담당자 리스트
            f_unassign : '',                    // 미배정사유
            list_unassign : [],                 // 미배정사유 리스트
            f_safecare : '',                    // 자가치료사유
            list_safecare : [],                 // 자가치료사유 리스트
            list_safecare_org : [],             // 자가치료사유 리스트
            f_safecare_etc_reason : '',         // 자가치료기타사유
            list_unassign_org : [],             // 미배정사유 리스트
            f_unassign_reason : '',             // 기타사유
            f_result : '',                      // 최종결과
            list_result : [],                   // 최종결과 리스트
            f_now_state : 'G1601',                   // 현재위치
            list_now_state : [],                // 현재위치 리스트
            f_idx:'',                           // 고유번호
            f_extra_3 : '',
            f_extra_4 : 'KR',
            f_extra_5 : '',
            f_manager_name : '',
            list_assign_order : [],              // 배정순서 리스트
            f_assign_order : 'G1501'                  // 배정순서
        }
    },
    components: { mainHeader, mainFooter, datepicker },
    // 계산된 속성
    computed: {
    },
    // 감시자
    watch: {
        // 배정 대상 선택에 따른 옵션 변경
        f_assign_type(_new){
            let infoFasilityCode = this.storageGet('pageInfo', 'info').ffasilityCode;
            let infoSafecareCode = this.storageGet('pageInfo', 'info').fsafecareCode;
            if (_new == 'G0202') {
                this.f_manager = 'G0101';
                this.f_fasility = infoFasilityCode ? infoFasilityCode : "";
            } else if (_new == 'G0201'){
                this.f_manager = 'G0103';
                this.f_fasility = infoFasilityCode ? infoFasilityCode : "";
            } else if (_new == 'G0206'){
                this.f_manager = 'G0101';
                this.f_fasility = '181';
                this.f_safecare = infoSafecareCode ? infoSafecareCode : 'G1405';
            } else {
                this.f_manager = 'G0102';
                this.f_fasility = infoFasilityCode ? infoFasilityCode : "";
            }
            this.listFasilitySetFunc();

            if (this.f_assign_state_view == 'G0301' || this.f_assign_state_view == 'G0314'){
                this.listUnassignSetFunc();
            }
        },
        // 배정 여부에 따른 옵션 변경
        f_assign_state_view(){
            this.listFasilitySetFunc()
        },
        f_fasility(_new) {
            const selectedData = this.list_fasility.find(item => item.code === _new);
            if (selectedData) {
                this.f_fasility_upper_code = selectedData.upperCode;
            } else {
                this.f_fasility_upper_code = ''; // 선택된 항목이 없을 경우 초기화
            }
        }
    },
    // $el 생성전
    created() {
        this.checkLogin();
        this.init();
    },
    // $el 생성후
    mounted() {
        this.$nextTick(function() {
            if(!this.storageGet('pageInfo')) this.storageSet('pageInfo', this.$route.params.data);

            let info = this.storageGet('pageInfo', 'info');
            // info.f_idx 있으면 수정이라 판단 데이터 요청하여 더 불러옴.
            // 없으면 등록으로 판단. 기초정보만 입력 후 통과.
            if (info.f_idx){
                let params = `{"fIdx":${info.f_idx}}`;
                let queryString = encodeURIComponent(params); // params를 URL 인코딩하여 쿼리 문자열로 변환
                this.ajaxGet('nocConfirmCase/detail?params=' + queryString, null, 'initInfo');

                /* 기존 방식
                this.f_region = info.f_region_code;
                this.f_confirm_date = this.$moment(info.f_confirm_date).format('YYYY-MM-DD');
                this.f_reg_date = this.$moment(info.f_reg_date).format('YYYY-MM-DD');
                this.f_transfer_date = this.$moment(info.f_transfer_date).format('YYYY-MM-DD');
                this.f_patient_name = info.f_patient_name;
                this.f_date_num = info.f_date_num;
                this.f_patient_sex = info.f_patient_sex_code;
                this.f_patient_birth = this.$moment(info.f_patient_birth).format('YYYY-MM-DD');
                this.f_assign_type = info.f_assign_type_code;
                this.f_assign_state_view = (info.f_assign_state_code == 'G0301' || info.f_assign_state_code == 'G0312' || info.f_assign_state_code == 'G0313' || info.f_assign_state_code == 'G0314') ? info.f_assign_state_code : 'G0302';
                this.f_assign_date = (info.f_assign_date != '0000-00-00 00:00:00') ? this.$moment(info.f_assign_date).format('YYYY-MM-DD') : this.$moment(new Date()).format('YYYY-MM-DD');
                this.f_fasility = info.f_fasility_code;
                this.f_assign_state = info.f_assign_state_code;
                this.f_manager = info.f_manager_code;
                this.f_unassign = info.f_unassign_code;
                this.f_now_state = info.f_now_state_code;
                this.f_safecare = (info.f_fasility_code == '181' && info.f_safecare_code) ? info.f_safecare_code : '0';
                this.f_safecare_etc_reason = (info.f_fasility_code == '181' && info.f_safecare_etc_reason) ? info.f_safecare_etc_reason : '';
                this.f_unassign_reason = (info.f_unassign_etc_reason) ? info.f_unassign_etc_reason : '';
                this.f_idx = info.f_idx;
                this.f_extra_3 = info.f_extra_3;
                this.f_extra_4 = info.f_extra_4;
                this.f_extra_5 = info.f_extra_5;
                this.f_result = info.f_result_code;
                this.f_assign_order = info.f_assign_order_code;
                this.f_manage_check = (info.f_manage_check) ? info.f_manage_check : 'N';
                */
            }else{
                this.f_confirm_date = this.$moment(new Date()).format('YYYY-MM-DD');
                this.f_reg_date = this.$moment(new Date()).format('YYYY-MM-DD');
                this.f_assign_date = this.$moment(new Date()).format('YYYY-MM-DD');
                this.f_transfer_date = this.$moment(info.f_transfer_date).format('YYYY-MM-DD');
                this.f_assign_type = 'G0202';
                this.f_result = '';
                this.f_assign_order = 'G1501';
                this.f_manage_check = 'N';

            }
        });
    },
    methods: {
        reset(){
            this.f_region = '';
            this.f_confirm_date = this.$moment(new Date()).format('YYYY-MM-DD');
            this.f_reg_date = this.$moment(new Date()).format('YYYY-MM-DD');
            this.f_transfer_date = this.$moment(new Date()).format('YYYY-MM-DD');
            this.f_patient_name = '';
            this.f_date_num = '';
            this.f_patient_sex = 'M';
            this.f_patient_birth = '';
            this.f_assign_type = 'G0202';
            this.f_result = '';
            this.f_assign_state_view= 'G0301';
            this.f_assign_date = this.$moment(new Date()).format('YYYY-MM-DD');
            this.f_fasility = '';
            this.f_assign_state = '';
            this.f_manager = 'G0101';
            this.f_unassign = '';
            this.f_unassign_reason = '';
            this.f_safecare = '';
            this.f_now_state = 'G1601';
            this.f_safecare_etc_reason = '';
            this.f_idx = '';
            this.f_extra_3 = '';
            this.f_extra_4 = 'KR';
            this.f_extra_5 = '';
            this.f_assign_order = 'G1501';
            this.f_manage_check = 'N';
            this.$refs.f_region.focus()
        },
        init(){
            // let formData = new FormData();
            // formData.append('api_name', 'patientCreateCode');
            // this.ajaxPost('', formData,'initResult');

            this.ajaxGet('nocCommon/code?params=', null, 'initResult');
        },
        initResult(response){
            let result = response;

            // 지역별
            this.list_region = result.regionList;
            this.list_region.unshift({ code:'', name:'선택' });

            // 성별
            this.list_sex = result.sexList;

            // 국가
            this.list_country = result.countryList;

            // 비고
            this.list_etc = result.remarkList;

            // 배정순서
            this.list_assign_order = result.assignOrderList;

            // 배정대상
            this.list_assign_type = result.assignTypeList;

            // 시설별
            this.list_fasility_org = result.fasilityList;
            // this.list_fasility.unshift({ code:'', name:'선택' });

            // 배정상태
            this.list_assign_state = result.assignStateList;
            this.list_assign_state.splice(this.list_assign_state.findIndex(item => item.code === 'G0301'), 1);

            // 배정담당자
            this.list_manager = result.managerList;
            this.list_manager.unshift({ code:'', name:'선택' });

            // 미배정사유
            this.list_unassign_org = result.unassignList;

            // 자가치료사유
            this.list_safecare = result.safecareList;
            this.list_safecare.unshift({ code:'', name:'선택' });
            this.list_safecare_org = result.list_safecare;

            // 최종결과
            this.list_result = result.resultList;
            this.list_result.unshift({ code:'', name:'선택' });

            // 현재위치
            this.list_now_state = result.nowStateList;

            this.listFasilitySetFunc();
            this.listUnassignSetFunc();
        },
        initInfo(response){
            let result = response.list;
            const pageInfo = this.storageGet('pageInfo') || {};  // 기존 데이터 가져오기
            pageInfo.info = result;                                   // 새로운 데이터를 기존 데이터의 info 속성에 추가
            this.storageSet('pageInfo', pageInfo);              // 기존 데이터에 새로운 데이터 추가 후 저장

            let info = this.storageGet('pageInfo', 'info');
            if (info) {
                this.f_region = info.fregion;
                this.f_confirm_date = this.$moment(info.fconfirmDate).format('YYYY-MM-DD');
                this.f_reg_date = this.$moment(info.fregDate).format('YYYY-MM-DD');
                this.f_transfer_date = this.$moment(info.ftransferDate).format('YYYY-MM-DD');
                this.f_patient_name = info.fpatientName;
                this.f_date_num = info.fdateNum;
                this.f_patient_sex = info.fpatientSex;
                this.f_patient_birth = this.$moment(info.fpatientBirth).format('YYYY-MM-DD');
                this.f_assign_type = info.fassignTypeCode;
                this.f_assign_state_view = (info.fassignStateCode == 'G0301' || info.fassignStateCode == 'G0312' || info.fassignStateCode == 'G0313' || info.fassignStateCode == 'G0314') ? info.fassignStateCode : 'G0302';
                this.f_assign_date = (info.fassignDate != '0000-00-00 00:00:00') ? this.$moment(info.fassignDate).format('YYYY-MM-DD') : this.$moment(new Date()).format('YYYY-MM-DD');
                this.f_fasility = info.ffasilityCode;
                this.f_assign_state = info.fassignStateCode;
                this.f_manager = info.fmanagerCode;
                this.f_unassign = info.funassignCode;
                this.f_now_state = info.fnowStateCode;
                this.f_safecare = (info.ffasilityCode == '181' && info.fsafecareCode) ? info.fsafecareCode : '0';
                this.f_safecare_etc_reason = (info.ffasilityCode == '181' && info.fsafecareEtcReason) ? info.fsafecareEtcReason : '';
                this.f_unassign_reason = (info.funassignEtcReason) ? info.funassignEtcReason : '';
                this.f_idx = info.fidx;
                this.f_extra_3 = info.fextra3;
                this.f_extra_4 = info.fextra4;
                this.f_extra_5 = info.fextra5;
                this.f_result = info.fresultCode;
                this.f_assign_order = info.fassignOrder;
                this.f_manage_check = (info.fmanageCheck) ? info.fmanageCheck : 'N';
            }
        },
        listFasilitySetFunc(){
            let vm = this;
            vm.list_fasility = [];
            this.list_fasility_org.forEach(function(obj){
                if (['G0203', 'G0204', 'G0205'].includes(vm.f_assign_type)) {
                    if ('G0203' == obj.upperCode) {
                        vm.list_fasility.push(obj);
                    }
                } else if (['G0206'].includes(vm.f_assign_type)) {
                    if ('G0202' == obj.upperCode) {
                        vm.list_fasility.push(obj);
                    }
                } else {
                    // sortCode가 무엇인지와 배정대상과의 상관관계에 대해 파악이 불가하여 우선은 전부 목록에 넣어주도록 합니다.
                    // => 백에서 관리자 코드에 따라 치환해서 보내주는 값이다..
                    if (vm.f_assign_type == obj.upperCode){
                        vm.list_fasility.push(obj)
                    }
                }
            });
            this.list_fasility.unshift({ code:'', name:'선택' });

            // 확진자 정보 수정일 때만.
            if (this.storageGet('pageInfo', 'info').fidx !== undefined) {
                // 시설 목록에 현재의 시설코드가 있으면 그 시설명을 표출, 없으면 선택을 표출.
                if (this.list_fasility.some(item => item.code === this.storageGet('pageInfo', 'info').ffasilityCode)) {
                  this.f_fasility = this.storageGet('pageInfo', 'info').ffasilityCode;
                } else {
                  this.f_fasility = "";
                }
            }
        },
        listUnassignSetFunc(){
            let vm = this;
            vm.list_unassign = [];
            this.list_unassign_org.forEach(function(obj){
                if (['G0205'].includes(vm.f_assign_type)){
                    if ('G0205' == obj.group){
                        vm.list_unassign.push(obj)
                    }
                }else{
                    if ('G0205' != obj.group){
                        vm.list_unassign.push(obj)
                    }
                }
            });
        },

        sendFunc(){

            if (!this.f_region){
                this.$refs.f_region.focus()
                this.showAlert('', '지역을 선택해 주세요.');
                return false;
            }

            if (!this.f_confirm_date){
                this.showAlert('', '확진일을 선택해 주세요.');
                return false;
            }

            if (!this.f_reg_date){
                this.showAlert('', '접수일을 선택해 주세요.');
                return false;
            }

            if (!this.f_patient_name){
                this.$refs.f_patient_name.focus()
                this.showAlert('', '이름을 입력해 주세요.');
                return false;
            }

            if (!this.f_patient_sex){
                this.showAlert('', '성별을 선택해 주세요.');
                return false;
            }

            if (!this.f_patient_birth){
                this.showAlert('', '생년월일을 선택해 주세요.');
                return false;
            }

            if (!this.f_assign_type){
                this.showAlert('', '배정대상을 선택해 주세요.');
                return false;
            }

            if (this.f_fasility == '181') {
              if (!this.f_safecare) {
                this.showAlert('', '재택치료 사유를 선택해 주세요.');
                return false;
              }
            }

            let data = {
                'fConfirmDate' : this.$moment(this.f_confirm_date).format('YYYY-MM-DD'),
                'fRegDate' : this.$moment(this.f_reg_date).format('YYYY-MM-DD'),
                'fRegion' : this.f_region,
                'fPatientName' : this.f_patient_name,
                'fDateNum' : this.f_date_num,
                'fPatientSex' : this.f_patient_sex,
                'fPatientBirth' : this.$moment(this.f_patient_birth).format('YYYY-MM-DD'),
                'fAssignTypeCode' : this.f_assign_type,
                // '' : this.userDataAdminId,
                'fExtra3' : this.f_extra_3,
                'fExtra4' : this.f_extra_4,
                'fExtra5' : this.f_extra_5,
                'fTransferDate' : this.$moment(this.f_transfer_date).format('YYYY-MM-DD'),

                'fAdminName' : this.userDataAdminName,

                'fAssignOrder' : this.f_assign_order,

                'fNowStateCode' : this.f_now_state,

                'fManageCheck' : this.f_manage_check,

                'adminId' : this.userDataAdminId,
            };

            if (this.f_idx) {
                data.fIdx = this.f_idx;
            }

            // 배정여부가 미배정일 경우
            if (this.f_assign_state_view == 'G0301') {
                data.fAssignDate = '0000-00-00 00:00:00';
                data.fFasilityCode = '';
                data.fFasilityTypeCode = '';
                data.fManagerCode = '';
                data.fAssignStateCode = 'G0301';
                data.fResultCode = '';

                data.fUnassignCode = this.f_unassign;
                data.fUnassignEtcReason = this.f_unassign_reason;

                data.fSafecareCode = '';
                data.fSafecareEtcReason = '';
            } else if (this.f_assign_state_view == 'G0314') {
                data.fAssignDate = '0000-00-00 00:00:00';
                data.fFasilityCode = '';
                data.fFasilityTypeCode = '';
                data.fManagerCode = '';
                data.fAssignStateCode = 'G0314';
                data.fResultCode = '';

                data.fUnassignCode = '';
                data.fUnassignEtcReason = this.f_unassign_reason;

                data.fSafecareCode = '';
                data.fSafecareEtcReason = '';
            } else {
                data.fAssignDate = this.$moment(this.f_assign_date).format('YYYY-MM-DD');
                data.fFasilityCode = this.f_fasility;
                data.fFasilityTypeCode = this.f_fasility_upper_code;
                data.fManagerCode = this.f_manager;
                data.fAssignStateCode = this.f_assign_state;
                data.fResultCode = this.f_result;

                data.fUnassignCode = '';
                data.fUnassignEtcReason = '';

                data.fSafecareCode = this.f_safecare;
                data.fSafecareEtcReason = this.f_safecare_etc_reason;
            }

            if (this.f_idx) {
                this.ajaxPost('nocConfirmCase/update', data,'sendFuncResult');
            } else {
                this.ajaxPost('nocConfirmCase/regist', data,'sendFuncResult');
            }
            /*
            let formData = new FormData();
            if (this.f_idx){
                formData.append('api_name', 'patientUpdate');
                formData.append('f_idx', this.f_idx);
            }else{
                formData.append('api_name', 'patientCreate');
            }

            formData.append('f_confirm_date', this.$moment(this.f_confirm_date).format('YYYY-MM-DD'));
            formData.append('f_reg_date', this.$moment(this.f_reg_date).format('YYYY-MM-DD'));

            formData.append('f_region', this.f_region);
            formData.append('f_patient_name', this.f_patient_name);
            formData.append('f_date_num', this.f_date_num);
            formData.append('f_patient_sex', this.f_patient_sex);
            formData.append('f_patient_birth', this.$moment(this.f_patient_birth).format('YYYY-MM-DD'));
            formData.append('f_assign_type', this.f_assign_type);
            formData.append('f_admin_id', this.userDataAdminId);
            formData.append('f_extra_3', this.f_extra_3);
            formData.append('f_extra_4', this.f_extra_4);
            formData.append('f_extra_5', this.f_extra_5);
            formData.append('f_transfer_date', this.$moment(this.f_transfer_date).format('YYYY-MM-DD'));

            formData.append('f_admin_name', this.userDataAdminName);

            formData.append('f_assign_order', this.f_assign_order);

            formData.append('f_now_state', this.f_now_state);

            formData.append('f_manage_check', this.f_manage_check);

            // 배정여부가 미배정일 경우
            if (this.f_assign_state_view == 'G0301') {
              formData.append('f_assign_date', '0000-00-00 00:00:00');
              formData.append('f_fasility', '');
              formData.append('f_manager', '');
              formData.append('f_assign_state', 'G0301');
              formData.append('f_result', '');

              formData.append('f_unassign', this.f_unassign);
              formData.append('f_unassign_reason', this.f_unassign_reason);
            }else if(this.f_assign_state_view == 'G0314'){
              formData.append('f_assign_date', '0000-00-00 00:00:00');
              formData.append('f_fasility', '');
              formData.append('f_manager', '');
              formData.append('f_assign_state', 'G0314');
              formData.append('f_result', '');

              formData.append('f_unassign', '');
              formData.append('f_unassign_reason', this.f_unassign_reason);
            }else{

                formData.append('f_assign_date', this.$moment(this.f_assign_date).format('YYYY-MM-DD'));
                formData.append('f_fasility', this.f_fasility);
                formData.append('f_manager', this.f_manager);
                formData.append('f_assign_state', this.f_assign_state);
                formData.append('f_result', this.f_result);

                formData.append('f_unassign', '');
                formData.append('f_unassign_reason', '');

                formData.append('f_safecare',this.f_safecare);
                formData.append('f_safecare_etc_reason', this.f_safecare_etc_reason);
            }

            this.ajaxPost('', formData,'sendFuncResult');
             */
        },
        sendFuncResult() {
            if (this.f_idx){
                this.showAlert('', '저장 되었습니다.',"listBackFunc");
            }else{
                this.showAlert('', '저장 되었습니다.',"reset");
            }

        },
        listBackFunc(){
            this.$router.push({ name: 'AdminConfirmedList', params: this.storageGet('pageInfo')})
        },
    },
    destroyed(){
        this.storageRemove('pageInfo');
    }
}
</script>

<style scoped>
</style>
